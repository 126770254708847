<template>
  <div class="careers">
    <hero-page class="pb-10" :illustration="illustration" title="Careers">
      <template #title>
        <h1 :style="$vuetify.breakpoint.mdAndUp ? 'min-width: 50vw;' : ''" class="font-weight-bold text-uppercase text-h4 white--text">
          {{ content.page_title }}
        </h1>
      </template>

    </hero-page>
    <v-container id="careers-opportunities">
      <v-row class="d-flex align-center justify-center">
        <v-col class="px-6" cols="10">
          <input-filters @on-update="onUpdateFilter"/>
          <p v-if="!careerOpportunities.length">
            We currently have no positions available, but keep checking back!
          </p>
          <template v-if="showActiveJobInformation" >
            <v-card class="mb-5 elevation-0" >
              <v-card-title class="d-block d-md-flex">
                <h3 class="job-title">
                  {{ activeJob.title }}
                </h3>
                <div class="d-flex justify-space-between justify-md-end align-center flex-grow-1">
                  <v-btn class="text-none py-5 px-7 black text-caption job-apply" dark ref="applyButtonRef" rounded
                        @click="openApplyDialog(activeJob)">
                    Apply
                  </v-btn>
                  <span class="ml-5 more-info" @click="closeActiveJobInformation">
                    X
                  </span>
                </div>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <vue-markdown class="text-h6 job-desc">
                      {{ activeJob.description }}
                    </vue-markdown>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template v-else >
            <v-card class="mb-5 elevation-0" v-for="(job, index) in careerOpportunities" :key="index">
              <v-card-title class="d-block d-md-flex">
                <h3 class="job-title">
                  {{ job.title }}
                </h3>
                <div class="d-flex justify-space-between justify-md-end align-center flex-grow-1">
                  <span class="mr-5 more-info" @click="openInfoDialog(job)">
                    More Info...
                  </span>
                  <v-btn class="text-none py-5 px-7 black text-caption job-apply" dark ref="applyButtonRef" rounded
                        @click="openApplyDialog(job)">
                    Apply
                  </v-btn>
                </div>
              </v-card-title>
              <v-card-text class="career-card-text">
                <v-row>
                  <v-col>
                    <vue-markdown class="text-h6 job-desc">
                      {{ job.summary.substring(0, 255) }}...
                    </vue-markdown>
                  </v-col>
                </v-row>
              </v-card-text>
              <ul class="d-flex skills pb-3">
                <li v-for="{skill, id, color} in job.career_opportunity_skills" :key="id">
                  <span :style="{color}">#</span><span>{{ skill }}</span>
                </li>
              </ul>
              <v-divider v-if="index < careerOpportunities.length - 1"></v-divider>
            </v-card>
          </template>
          <v-dialog ref="applyFormRef" content-class="applicationForm" :width="!isMobile ? '50vw' : '80vw'" v-model="displayApplyDialog">
            <v-card class="pa-5">
              <v-card-title >
                <v-row class="d-flex" :class="$vuetify.breakpoint.mdAndUp ? '' : 'flex-column-reverse' " >
                  <v-col class="d-flex justify-lg-center justify-lx-center text-caption col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 pr-0" >
                    Apply for {{ this.activeJob.title }} position.
                  </v-col>
                  <v-col class="closeButton" >
                    <v-btn icon @click="closeModal" >
                      <span class="black--text" >X</span>
                    </v-btn>
                  </v-col>
                </v-row> 
              </v-card-title>
              <v-card-text>
                <v-row class="mt-2">
                  <v-col class="d-flex flex-column justify-center">
                    <v-form @submit.prevent="processFiles">
                      <v-row>
                        <v-col class="col-12 col-md-6" >
                          <v-text-field label="First Name" v-model="applicant.firstName" @change="(input) => (this.applicant.firstName = input)" required />
                        </v-col>
                        <v-col class="col-12 col-md-6" >
                          <v-text-field label="Last Name" v-model="applicant.lastName" @change="(input) => (this.applicant.lastName = input)" required/>
                        </v-col>
                        <v-col class="col-12 col-md-6" >
                          <v-text-field label="Email" v-model="applicant.emailAddress" @change="(input) => (this.applicant.emailAddress = input)"
                                      required/>
                        </v-col>
                        <v-col class="col-12 col-md-6" >
                          <v-text-field 
                            v-model="applicant.desiredRate"
                            label="Desired Hourly Rate (USD)"
                            @change="(input) => (this.applicant.desiredRate = input)"
                            required
                          />
                        </v-col>
                        <v-col class="col-6" >
                          <v-file-input 
                            placeholder="Resume (required, pdf)" 
                            accept=".pdf" 
                            chips
                            :rules="rules"
                            v-model="applicant.resume"
                            @change="(input) => (this.applicant.resume = input)"
                          />
                        </v-col>
                        <v-col class="col-6" >
                          <v-file-input 
                            placeholder="Cover Letter (optional, pdf)" 
                            accept=".pdf"
                            chips
                            :rules="rules"
                            v-model="applicant.coverLetter"
                            @change="(input) => (this.applicant.coverLetter = input)"
                          />
                        </v-col>
                        <v-col class="col-6" >
                          <recaptcha class="d-flex justify-center mb-3" v-if="false"
                                  @on-captcha-verification="captchaWasVerified = true"/>
                        </v-col>
                        <v-col class="col-12" >
                          <div class="d-flex justify-center">
                            <v-btn rounded class="d-flex submit-btn black--text" color="#60C58F" type="submit" :loading="loading">
                              Submit
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="displayConfirmDialog" width="480">
            <v-card class="pa-5">
              <v-card-title>Application for {{ this.activeJob.title }} position successfully submitted!
              </v-card-title>
            </v-card>
          </v-dialog>
          <v-dialog v-model="displayErrorDialog" width="480">
            <v-card class="pa-5">
              <v-card-title>{{ this.error }}</v-card-title>
            </v-card>
          </v-dialog>
          <v-pagination v-if="pageLength > 1" v-model="page" :length="pageLength" @input="onPageChange"></v-pagination>
        </v-col>
      </v-row>
    </v-container>
    <ScrollToTopButton/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {debounce} from "lodash";
import InputFilters from "@/components/pages/careers/InputFilters.vue";
import Recaptcha from "@/components/Recaptcha.vue";
import qs from "qs";
import HeroPage from "@/components/ui/HeroPage.vue";
import VueMarkdown from "vue-markdown";
import ScrollToTopButton from "@/components/ui/ScrollToTopButton";

export default {
  name: "base-careers-page-component",
  model: {
    event: "updateModel",
  },
  components: {ScrollToTopButton, HeroPage, InputFilters, VueMarkdown, Recaptcha},
  data() {
    return {
      showActiveJobInformation: false,
      loading: false,
      pageLength: 0,
      page: 1,
      count: 0,
      limit: 10,
      captchaWasVerified: false,
      filterData: null,
      menu: false,
      careerOpportunities: [],
      careerOportunitySkills: [],
      displayInfoDialog: false,
      displayApplyDialog: false,
      displayConfirmDialog: false,
      displayErrorDialog: false,
      error: "",
      activeJob: {},
      applicant: {
        firstName: "",
        lastName: "",
        position: "",
        emailAddress: "",
        desiredRate: "",
        resume: null,
        coverLetter: null,
      },
      applicantFiles: [],
      resumeFile: {},
      coverLetterFile: {},
      rules: [
          value => !value || value.size < 2000000 || 'File size should be less than 2 MB!'
      ]
    };
  },
  computed: {
    ...mapGetters("careersPageStore", {
      content: "careersPageContent",
    }),
    illustration() {
      return this.content?.page_illustration?.url
    },
    isFilteringBySkills() {
      return !!this.filterData?.career_opportunity_skills_in?.length;
    },
    isFiltering() {
      const filters = [
        this.filterData?.title_contains,
        this.filterData?.salary_gte,
        this.filterData?.salary_lte,
        this.isFilteringBySkills,
      ];
      return filters.filter(Boolean).length;
    },
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    },
    isSubmitButtonDisabled() {
      return !this.captchaWasVerified;
    },
  },
  mounted() {
    this.getInitialData();
  },
  methods: {
    ...mapActions("applicantsStore", ["createApplicant"]),
    ...mapActions("careerOpStore", ["getCareerOpportunities"]),
    ...mapActions("careersPageStore", ["getContent"]),
    async getInitialData() {
      this.getCareerOpportunitiesFiltered();
      this.getContent();
      this.getCareerOpportunities();
    },
    closeActiveJobInformation() {
      this.showActiveJobInformation = false;
      this.activeJob = {};
    },
    openInfoDialog(job) {
      this.showActiveJobInformation = true;
      this.activeJob = job;
    },
    openApplyDialog(job) {
      this.displayApplyDialog = true;
      this.activeJob = job;
    },
    processApplicant() {
      axios
          .post(`${this.$api_url}/applicants`, {
            firstName: this.applicant.firstName,
            lastName: this.applicant.lastName,
            position: this.activeJob.title,
            emailAddress: this.applicant.emailAddress,
            resume: this.resumeFile,
            coverLetter: this.coverLetterFile,
          })
          .then(() => {
            this.displayConfirmDialog = true;
            this.displayApplyDialog = false;
            this.loading = false;
          })
          .catch((err) => {
            this.error = err;
            this.displayErrorDialog = true;
            this.loading = false;
          });
    },
    async getCareersCount() {
      try {
        const {
          title_contains,
          salary_gte,
          salary_lte,
          career_opportunity_skills_in,
        } = this.filterData || {};

        const query = qs.stringify({
          _where: [
            {
              title_contains,
            },
            {
              salary_gte,
            },
            {
              salary_lte,
            },
            {
              career_opportunity_skills_in,
            },
          ],
        });
        const response = await fetch(
            `${this.$api_url}/career-opportunities/count?${query}`
        );
        this.count = await response.json();
      } catch (error) {
        console.error(error);
      }
    },
    async getCareerOpportunitiesFiltered() {
      await this.getCareersCount();
      this.pageLength = Math.ceil(this.count / this.limit);
      const careerOpportunities = await this.getCareerOpportunities(
          this.getFilterData()
      );
      this.careerOpportunities = careerOpportunities;
    },
    getFilterData() {
      return {
        ...this.filterData,
        start: +this.page === 1 ? 0 : (+this.page - 1) * this.limit,
        limit: this.limit,
      };
    },
    processFiles() {
      this.loading = true;
      const formData = new FormData();

      const getFileType = (property) => {
        if (this.applicant[property].type === "application/pdf") {
          return "pdf";
        } else if (this.applicant[property].type === "image/png") {
          return "png";
        } else if (this.applicant[property].type === "image/jpeg") {
          return "jpg";
        }
      };

      if (this.applicant.resume) {
        formData.append(
            "files",
            this.applicant.resume,
            `resume.${getFileType("resume")}`
        );
      }
      if (this.applicant.coverLetter) {
        formData.append(
            "files",
            this.applicant.coverLetter,
            `coverLetter.${getFileType("coverLetter")}`
        );
      }

      axios
          .post(`${this.$api_url}/upload`, formData)
          .then((res) => (this.applicantFiles = res.data))
          .then(() => {
            this.resumeFile = this.applicantFiles.find((file) =>
                file.name.includes("resume")
            );
            this.coverLetterFile = this.applicantFiles.find((file) =>
                file.name.includes("cover")
            );
            this.processApplicant();
          })
          .catch((err) => {
            // this.error = `Error: ${err.response.data.data.errors[0].message}`;
            this.displayErrorDialog = true;
            this.loading = false;
          });
    },
    onUpdateFilter: debounce(function (filterData) {
      this.filterData = {...filterData};
      this.page = 1;
      this.getCareerOpportunitiesFiltered();
    }, 150),
    onPageChange() {
      this.getCareerOpportunitiesFiltered();
    },
    closeModal() {
      console.log('this.careerOpportunities', this.careerOpportunities)
      this.displayApplyDialog = false;
      this.applicant = {
        firstName: "",
        lastName: "",
        position: "",
        emailAddress: "",
        desiredRate: "",
        resume: null,
        coverLetter: null,
      };
    }
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (min-width: 2000px) {
  .career-title {
    font-size: 2em !important;
    min-width: 36vw !important;
  }
  .job-title {
    font-size: 1.2rem !important;
  }
  .job-desc {
    font-size: .8rem !important;
    line-height: 1.5em;
  }
  .more-info{
    font-size: 1rem !important;
    margin-right: 4vw !important;
  }
  .info-dialog {
    max-width: 1600px !important;
  }
}
@include screen('onlySm') {
  .career-title {
    height: 100%;
    padding-top: 20%;
  }
}
@include screen('onlyMd') {
  .career-title {
    height: 100%;
    padding-top: 15%;
  }
}
.careers {
  position: relative;
  min-height: 100vh;

  h1 {
    max-width: 20ch;
  }

  &__button--filter {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 3;
  }

  .skills {
    list-style: none;
    display: flex;
    gap: 1rem;
  }

  .aside {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 4.5rem;
    align-self: flex-start; /* <-- this is the fix */
  }
}

.colorful-text {
  color: #fff;
  display: block;
  position: relative;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;

  &::before {
    content: "More Info...";
    background: linear-gradient(
            90deg,
            rgba(248, 30, 140, 1) 0%,
            rgba(4, 223, 117, 1) 100%
    );
    position: absolute;
    inset: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.menu-card-form {
  min-width: 280px;
}

::v-deep ul {
  margin-bottom: 1rem;
}

    .job-title {
    font-size: 1.2rem !important;
    overflow-wrap: normal !important;
  }
  .job-desc {
    font-size: .8rem !important;
    line-height: 1.5em;
  }
  .more-info{
    font-size: 1rem !important;
    margin-right: 4vw !important;
    cursor: pointer;
  }

  .submit-btn {
    width: 10rem;
  }

  .applicationForm.v-dialog {
    width: 50vw !important;
    z-index: 4000 !important;
    @media only screen and (max-width: 680px) {
      width: 80vw !important;
    }
  }

  .closeButton {
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
