<template>
  <div class="d-md-flex align-center">
    <v-text-field
      v-model="filterData.title"
      solo
      placeholder="Job Title Name"
      clearable
      flat
      background-color="grey lighten-3"
      rounded="pill"
      @input="onUpdateFilter"
      class="my-3 input-shadow input-field"
      style="border: none;"
      hide-details="auto"
      :dense="$vuetify.breakpoint.mobile"
    />
    <div class="d-flex">
    <v-autocomplete
      v-model="filterData.selectedSkills"
      multiple
      class="my-3 ml-2 input-shadow input-field"
      :items="careerOportunitySkills"
      item-value="id"
      item-text="skill"
      placeholder="Skills"
      chips
      deletable-chips
      solo
      flat
      background-color="grey lighten-3"
      rounded="pill"
      @input="onUpdateFilter"
      hide-details="auto"
      :dense="$vuetify.breakpoint.mobile"
    >
      <template v-slot:selection="data">
        <v-chip
          close
          :color="data.item.color"
          dark
          label
          small
          @click:close="removeSelectedSkill(data.item.id)"
        >
          {{ data.item.skill }}
        </v-chip>
      </template>
    </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "InputFilters",
  data() {
    return {
      careerOportunitySkills: [],
      filterData: {
        minSalary: "",
        maxSalary: "",
        title: "",
        selectedSkills: [],
      },
    };
  },
  methods: {
    ...mapActions("careerOpStore", ["getCareerOpportunitySkills"]),
    isNumber(event) {
      event = (event) ? event : window.Event;
      let charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault();
      } else {
        return true
      }
    },
    onUpdateFilter() {
      this.$emit("on-update", {
        title_contains: this.filterData.title || "",
        ...(this.filterData.minSalary && {
          salary_gte: this.filterData.minSalary * 1,
        }),
        ...(this.filterData.maxSalary && {
          salary_lte: this.filterData.maxSalary * 1,
        }),
        ...(this.filterData.selectedSkills.length && {
          career_opportunity_skills_in: this.filterData.selectedSkills,
        }),
      });
    },
    async getInitialData() {
      this.careerOportunitySkills = await this.getCareerOpportunitySkills();
    },
    removeSelectedSkill(id) {
      this.filterData.selectedSkills = this.filterData.selectedSkills.filter(
        (skill) => skill !== id
      );
      this.onUpdateFilter();
    },
    resetFilters() {
      this.filterData.title = "";
      this.filterData.minSalary = null;
      this.filterData.maxSalary = null;
      this.filterData.selectedSkills = [];

      this.onUpdateFilter();
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style lang="scss" scoped>
.input-shadow {
  box-shadow: 0px 0px 3px #9e9e9e54;
}
::v-deep .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}
</style>
